<template>
  <section class="relative overflow-hidden w-full aspect-video h-[700px] items-center justify-center flex flex-col md:py-20 md:px-5">
    <ClientOnly>
      <img v-if="hasInit" src="~/assets/img/home/youtube-blur.jpg" class="blur-xl absolute inset-0 object-cover w-full h-full z-0" loading="lazy" alt="MattePaint Trailer Background" />
    </ClientOnly>
    <div class="bg-black-800 aspect-video h-[700px] w-full max-w-6xl flex justify-center items-center group relative z-10 cursor-pointer" @click.stop.prevent="loadVideo">
      <iframe
        v-if="showVideo"
        width="560"
        height="315"
        src="https://www.youtube-nocookie.com/embed/VV2UFMrCNIQ?autoplay=1"
        title="YouTube video player"
        frameborder="0"
        autoplay
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        class="absolute w-full h-full inset-0 z-20"
      ></iframe>
      <ClientOnly>
        <img v-if="hasInit" src="~/assets/img/home/youtube.jpg" class="absolute inset-0 object-cover w-full h-full z-0 shadow-md" loading="lazy" alt="MattePaint Trailer Screenshot" />
      </ClientOnly>
      <i class="bg-white/25 rounded-full w-20 h-20 flex justify-center items-center cursor-pointer group-hover:scale-105 z-0 animate-ping absolute duration-1000" />
      <i class="bg-lightgray-400/10 rounded-full w-20 h-20 flex justify-center items-center cursor-pointer group-hover:scale-105 z-10"><svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-20 h-20"
      >
        <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        <path stroke-linecap="round" stroke-linejoin="round" d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z" />
      </svg>
      </i>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      showVideo: false,
    };
  },
  methods: {
    loadVideo() {
      this.showVideo = true;
    }
  }
}
</script>